import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Erp Sanbox
export const BRAND_NAME = "ProfessioNail"; // App Name
export const SANDBOX = false;
export const VERSION = "v0.1.16";

export const OWNER = "h8Xr8oyfXTavnkAGiGdnfTerMQr2"; // Moon Le moonle309@gmail.com

const firebaseConfig = {
  apiKey: "AIzaSyDvL-M22wlNpzVEH_iNvfMpcXBPkA2DQCw",
  authDomain: "professionail.firebaseapp.com",
  projectId: "professionail",
  storageBucket: "professionail.appspot.com",
  messagingSenderId: "321432185577",
  appId: "1:321432185577:web:32ad94cd385ca6e8191e78",
  measurementId: "G-XGH32JVGTB",
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export const authApp = firebase.initializeApp(firebaseConfig, "authApp");

// ========================= App String ========================= //
export const URL = {
  // url
  home: "/",
  signin: "/signin",
  request: "/request",
  requestAdd: "/request_add",
  requestDetail: "/request_detail",
};
